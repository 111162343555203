import React from "react";
import {
    BrowserRouter
} from "react-router-dom";
import {
    ShopCrateManager
} from "@shopcrate/shopcrate-framework";

import {
    ThemeManager
} from "./context/ThemeManager";
import ShopRouter from "./ShopRouter";

import "./scss/main.scss";

export const devApi = process.env.REACT_APP_SHOPCRATE_API_URL !== "api.shopcrate.nl";

function App() {
    return (
        <BrowserRouter>
            <ShopCrateManager>
                <ThemeManager>
                    <ShopRouter/>
                </ThemeManager>
            </ShopCrateManager>
        </BrowserRouter>
    );
}

export default App;
