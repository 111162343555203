import React from "react";
import {
    useRouteMatch
} from "react-router-dom";

import Helmet from "../components/Helmet";
import PrivacyNoticeCard from "../components/PrivacyNoticeCard";

function Layout({ children, title }) {
    const orderOverviewMatch = useRouteMatch({
        path: "/order/overview"
    });
    return (
        <React.Fragment>
            <Helmet title={ title }/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm mb-0">
                <div className="container py-4 text-blue" style={{ textTransform: "uppercase" }}>
                    <h1>{ title }</h1>
                </div>
            </div>
            <div className="container mb-5">
                { children }
                { orderOverviewMatch && (
                    <PrivacyNoticeCard/>
                )}
            </div>
        </React.Fragment>
    )
}

export default React.memo(Layout);
