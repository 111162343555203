import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({ siteName = "Cyber Atelier", title = undefined, description = undefined }) {
    const titleManaged = !title ? siteName : (title + " - " + siteName);
    return (
        <ReactHelmet>
            <meta name="og:site_name" content={ siteName }/>

            <title>{ titleManaged }</title>
            <meta name="title" content={ titleManaged }/>
            <meta property="og:title" content={ titleManaged }/>
            <meta name="twitter:title" content={ titleManaged }/>

            <meta name="description" content={ description }/>
            <meta property="og:description" content={ description }/>
            <meta name="twitter:description" content={ description }/>
            <meta name="summary" content={ description }/>
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
