import React from "react";
import ReactPlayer from "react-player";

import Helmet from "../components/Helmet";
import Image from "../components/Image";

import hashtagLogo from "../img/hashtag.png";

function AboutUs() {
    return (
        <React.Fragment>
            <Helmet
                title="About us"
            />
            <div className="container py-5 text-blue">
                <h1 className="text-center my-5" style={{ fontSize: "5rem" }}>
                    ABOUT CYBER ATELIER
                </h1>

                <div className="row justify-content-center">
                    <div className="col mb-4">
                        <div className="d-flex h-100 align-items-center">
                            <p className="text-center" style={{ fontSize: "2rem" }}>
                                “WE STARTED THIS COMMUNITY TO
                                INSPIRE, TO GIVE YOUNG CREATORS A
                                PLATFORM AND TO CREATE A SPACE
                                WHERE YOU FEEL FREE TO START
                                COLLABORATIONS."
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row text-center my-5 pt-5">
                    <div className="col-md-3"/>
                    <div className="col-md-6 mb-3">
                        <h2>BE INSPIRED</h2>
                        <p>
                            These days it is so difficult to meet
                            new people. Where do you find similar
                            creatives? We want to design a place
                            where we can come together to inspire
                            each other.
                        </p>
                    </div>
                    <div className="col-md-3">
                        <Image
                            src={ hashtagLogo }
                            className="img-fluid"
                            alt="Us"
                        />
                    </div>
                </div>

                <div className="row text-center my-5 pt-5">
                    <div className="col-md-6 mb-3">
                        <h2>START COLLABORATIONS</h2>
                        <p>
                            A place where you can find people with
                            whom you can make fantastic things.
                            You are stronger together. More
                            perspective and highlighting your
                            strengths even more.
                        </p>
                    </div>
                    <div className="col-md-6 mb-3">
                        <h2>GROW YOUR PORTFOLIO</h2>
                        <p>
                            On a TFP basis you can make beautiful
                            things and help each other with that.
                            everyone can build up their portfolio
                            in a low-threshold way.
                        </p>
                    </div>
                </div>

                <div className="text-center mb-5">
                    <a href="https://instagram.com/cyberatelier" className="btn btn-lg btn-blue" target="_blank" rel="noopener noreferrer">
                        Join the Platform
                    </a>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=NF7Gx6kPN7M"
                            className="mt-4 embed-responsive embed-responsive-16by9"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(AboutUs);
