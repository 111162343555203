import React from "react";
import Helmet from "../components/Helmet";

function TermsAndConditions() {
    return (
        <React.Fragment>
            <Helmet
                title="Terms and Conditions"
            />
            <div className="container py-5">
                <h1 className="text-center my-5 text-blue" style={{ textTransform: "uppercase" }}>
                    Terms and Conditions
                </h1>

                <div className="card">
                    <div className="card-body">
                        <p>
                            Shop Cyber Atelier online via: <a href="https://cyber-atelier.art">www.cyber-atelier.art</a>
                        </p>
                        <p>
                            If you have questions and / or comments about our products or your order, you can reach
                            Cyber Atelier via:<br/>
                            e-mail: <a href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>
                        </p>

                        <ul>
                            <li>
                                <b>Cyber Atelier</b>: established in Amsterdam under Netherlands Chamber of Commerce no. 89047311.
                            </li>
                            <li>
                                <b>Customer</b>: the person with whom Cyber Atelier has entered into an agreement.
                            </li>
                            <li>
                                <b>Parties</b>: Cyber Atelier and customer together.
                            </li>
                            <li>
                                <b>Consumer</b>: a customer who is an individual and who acts as a private person.
                            </li>
                        </ul>

                        <h2>1. Applicability</h2>
                        <p>
                            The general terms and conditions of Cyber Atelier online webshop apply to all transactions between
                            customers and Cyb#t Atelier that are made via the Cyber Atelier online webshop
                            (<a href="https://cyber-atelier.art">www.cyber-atelier.art</a>).
                        </p>

                        <h2>2. Prices</h2>
                        <p>
                            The prices for the products ordered in the Cyber Atelier online webshop are the prices in
                            the shopping basket which are displayed just before the order is sent. The prices include the
                            <b>Dutch VAT rate</b>.
                        </p>

                        <h2>3. Delivery Costs</h2>
                        <p>
                            Shipping costs differ between the Netherlands and abroad. Shipping costs are not included in the
                            general price. These will be added later in the shopping cart to the price of the general product.
                        </p>

                        <h2>4. Agreement</h2>
                        <p>
                            To place an order with the Cyber Atelier online webshop, the customer must click on the
                            “send order” button. Cyber Atelier will confirm receipt of the order by e-mail and also on the
                            following internet page. This does not constitute acceptance of the order, but is merely a
                            confirmation of the receipt of the correct order data. The order data are stored with us. If the
                            customer wishes to keep them, the order page should be printed out.
                        </p>
                        <p>
                            Cyber Atelier is entitled to refuse the order wholly or in part on the basis of unexpected
                            difficulties with the delivery or a negative creditworthiness of the customer. Cyber Atelier
                            will inform the customer of this by e-mail within three working days after such circumstances
                            become known. If the customer has already made an advance payment for undelivered
                            products, this advance payment will be refunded to the customer.
                        </p>
                        <p>
                            An agreement between Cyber Atelier and the customer is reached when Cyber Atelier
                            has shipped the products ordered by the customer and confirmed this to the customer by
                            e-mail.
                        </p>
                        <p>
                            Cyber Atelier is entitled to accept the offer (which has been incorporated into the order)
                            within a period of seven working days. With regard to products from the same order that are not
                            mentioned in the sent confirmation, no sales agreement comes into being.
                        </p>

                        <h2>5. Retention of Title</h2>
                        <p>
                            Cyber Atelier retains title to the products until the purchase price has been paid in full.
                        </p>

                        <h2>6. Delivery and Transfer of Risk</h2>
                        <p>
                            Cyber Atelier works with pre-order. This means that there is a period in which the customer can
                            order the product. After this period in which the product can be ordered, the product will be
                            produced and shipped within 2 weeks.
                        </p>
                        <p>
                            The products are at the risk of the buyer from the moment the buyer or a third party
                            appointed by the buyer, who is not the carrier, receives the goods.
                        </p>

                        <h2>7. Liability</h2>
                        <p>
                            Cyber Atelier is only liable for damage suffered by you if this damage is attributable to Cyb#r
                            Atelier or if it is at the risk of Cyber Atelier on the basis of mandatory legal provisions.
                        </p>
                        <p>
                            Cyber Atelier is not liable for the consequences of incorrect or incomplete information in its
                            mailings or on the website.
                        </p>
                        <p>
                            Cyber Atelier is not liable for damage resulting from the use by you of an article supplied by
                            Cyber Atelier. If Cyber Atelier culpably fails to fulfill its obligations to you, Cyber Atelier is liable
                            to you for compensation for the damage you have suffered.
                        </p>
                        <p>
                            However, Cyber Atelier is only liable for direct damage, including:
                        </p>
                        <ul>
                            <li>
                                damage caused directly to material items;
                            </li>
                            <li>
                                Reasonable costs incurred to determine the cause and extent of the damage, insofar as they
                                relate to the direct damage as referred to here;
                            </li>
                            <li>
                                reasonable and demonstrable costs incurred to prevent or limit the
                                direct damage referred to here.
                            </li>
                        </ul>

                        <h2>8. Payment</h2>
                        <p>
                            Only after the full price has been paid, we will prepare the product for shipment after the
                            pre-order period. Only when the payment is completed, there is a full agreement.
                        </p>

                        <h2>9. Warranty</h2>
                        <p>
                            Each product undergoes several quality controls before it leaves our warehouse. Cyber Atelier
                            guarantees that the delivered items are free from defects.
                        </p>
                        <p>
                            Cyber Atelier does not give any guarantee (in a legal sense) to its customers. Minor
                            deviations, in particular with regard to the color of the products, do not constitute a defect that
                            justifies compensation.
                        </p>

                        <h2>10. Right of Revocation</h2>
                        <ol>
                            <li>
                                The customer has the right to terminate the agreement with Cyber Atelier without giving
                                any reason within fourteen days of receiving the product. The customer must send the
                                cancellation in text form (by letter or e-mail) to:<br/>
                                Cyber Atelier<br/>
                                Kermispad 119<br/>
                                1133 ZB Amsterdam<br/>
                                The Netherlands<br/>
                                Email: <a href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>
                            </li>
                            <li>
                                The customer must return the products to the address stated in article 10, paragraph 1.
                            </li>
                            <li>
                                The customer is obliged to return the products in the same condition in which they were
                                received. If the customer is unable to return the products in the same condition, he is not
                                entitled to receive the purchase price back.
                            </li>
                            <li>
                                If the customer terminates the agreement within the period mentioned in article 10,
                                paragraph 1, and returns the products in the same condition as they were received, Cyber Atelier
                                will refund the purchase price of the returned products within fourteen days after
                                termination.
                            </li>
                            <li>
                                When returning products, the customer must take the following into account: Only
                                products purchased via the Cyber Atelier online webshop can be returned;
                                Only products that are unused, in their original packaging and without damage will be
                                accepted as a return.
                            </li>
                        </ol>

                        <h2>11. Set-off, Retention and Transfer</h2>
                        <ol>
                            <li>
                                The customer is only entitled to set-off: in the event that the company is unable to meet its
                                payment obligation; in the event of counterclaims that are in legal connection with your obligation;
                                and, in the event of counterclaims that have been determined by the court or recognised by us.
                            </li>
                            <li>
                                The customer may only assert retention claims that are legally related to Cyber Atelier’s
                                obligations.
                            </li>
                            <li>
                                The assignment of the customer’s contractual rights to third parties is only possible with our
                                written consent.
                            </li>
                        </ol>

                        <h2>12. Final Provisions</h2>
                        <p>
                            The law of the Netherlands shall apply, with the exclusion of the UN Convention on
                            Contracts for the International Sale of Goods (CISG). The place of jurisdiction is Amsterdam.
                        </p>
                        <p>
                            By continuing to access or use Our Service after those revisions become effective, You agree to
                            be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please
                            stop using the website and the Service.
                        </p>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default React.memo(TermsAndConditions);
