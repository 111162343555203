import React from "react";
import {
    CategoryPageContext,
    CategoryPageManager,
    CategoryPageTitle
} from "@shopcrate/shopcrate-framework";

import CACategoryPageProducts from "./CACategoryPageProducts";

function CategoryPage(props) {
    return (
        <CategoryPageManager { ...props }>
            { props.helmet && (
                <CategoryPageContext.Consumer>
                    { categoryContext => props.helmet(categoryContext) }
                </CategoryPageContext.Consumer>
            )}
            <div className="container mb-5">
                <div className="text-blue" style={{ textTransform: "uppercase" }}>
                    <CategoryPageTitle/>
                </div>
                <CACategoryPageProducts/>
            </div>
        </CategoryPageManager>
    );
}

export default React.memo(CategoryPage);
