import React, {
    useContext,
    useState
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";
import {
    AuthenticationContext,
    CartProductCountBadge,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import {
    devApi
} from "../App";

import logo from "../img/cyber-atelier.png";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    const authenticationContext = useContext(AuthenticationContext);
    const shopContext = useContext(ShopContext);

    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }

    return (
        <React.Fragment>
            <RBNavbar bg="light" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand" href="/">
                        <img src={ logo } alt="Cyb#r Atelier"/>
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" exact onClick={ collapse }/>
                            <NavbarLink to="/about-us" title="About Us" onClick={ collapse }/>
                            <NavbarLink to={ "/category/" + (devApi ? 4 : 5) } title="Shop" onClick={ collapse }/>

                            { shopContext.shop && shopContext.shop.orderingEnabled && (
                                <NavbarLink to="/cart" title={
                                    <React.Fragment>
                                        <i className="fas fa-shopping-cart"/>
                                        <CartProductCountBadge className="ml-2"/>
                                    </React.Fragment>
                                } exact onClick={ collapse }/>
                            )}

                            { authenticationContext.supported && (
                                <NavbarLink to="/account" title={ <i className="fas fa-user fa-fw"/> } onClick={ collapse }/>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div style={{ height: "76px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
