import React from "react";

function Image({ alt, webpSrc, ...props }) {
    return (
        <picture>
            { webpSrc && (
                <source srcSet={ webpSrc } type="image/webp"/>
            )}
            <img alt={ alt } { ...props }/>
        </picture>
    )
}

export default React.memo(Image);
