import {
    useEffect
} from "react";
import {
    useHistory
} from "react-router-dom";
import ReactGA from "react-ga4";

const useTracking = () => {
    const { listen } = useHistory()

    useEffect(() => {
        return listen((location) => {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        })
    }, [listen])
}

export default useTracking;
