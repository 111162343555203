import React from "react";
import ReactPlayer from "react-player";
import {
    ProductReleaseCountdown
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";
import CollectionBar from "../components/CollectionBar";
import {
    devApi
} from "../App";
import HomeSocialLinks from "../components/HomeSocialLinks";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <div className="container text-center mb-5">
                <ProductReleaseCountdown
                    productId={ devApi ? 82 : 109 }
                    unreleased={ (countdown) => (
                        <div className="my-5">
                            <div className="d-flex flex-column align-items-center my-5 text-blue">
                                <div>
                                    <div className="text-left">
                                        <p className="m-0" style={{ fontSize: "1.2rem" }}>
                                            YOUNG CREATORS FOR YOUNG CREATORS
                                        </p>
                                    </div>
                                    <div>
                                        <h1 className="m-0" style={{ fontSize: "8rem" }}>
                                            ISSUE 06.
                                        </h1>
                                    </div>
                                    <div className="text-right">
                                        <p className="m-0" style={{ fontSize: "1.2rem" }}>
                                            CYBER ATELIER
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="text-monospace">
                                            { countdown }
                                        </h2>
                                        <h3 className="mt-5 mb-0">
                                            Follow us for sneakpeaks
                                        </h3>
                                        <HomeSocialLinks/>
                                    </div>
                                </div>
                            </div>
                            <CollectionBar collection="home" noTitle/>
                        </div>
                    )}
                >
                    <div className="d-flex flex-column align-items-center my-5 text-blue">
                        <div>
                            <div className="text-left">
                                <p className="m-0" style={{ fontSize: "1.2rem" }}>
                                    YOUNG CREATORS FOR YOUNG CREATORS
                                </p>
                            </div>
                            <div>
                                <h1 className="m-0" style={{ fontSize: "6rem" }}>
                                    ORDER ISSUE 06. NOW
                                </h1>
                            </div>
                            <div className="text-right">
                                <p className="m-0" style={{ fontSize: "1.2rem" }}>
                                    CYBER ATELIER
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="my-4 text-black-50">
                        <CollectionBar collection="home" noTitle/>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="card">
                            <div className="card-body">
                                <HomeSocialLinks/>
                            </div>
                        </div>
                    </div>
                </ProductReleaseCountdown>


                <div className="row justify-content-center mb-3">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=NF7Gx6kPN7M"
                            className="my-4 embed-responsive embed-responsive-16by9"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);
