import React from "react";
import Helmet from "../components/Helmet";

function Contact() {
    return (
        <div>
            <Helmet
                title="Contact"
            />
            <div className="container py-3">
                <h1 className="my-5 text-blue" style={{ textTransform: "uppercase" }}>
                    Contact
                </h1>

                <div className="card">
                    <div className="card-body">
                        <ul className="mt-2 fa-ul" style={ {
                            paddingLeft: "24px",
                            marginLeft: 0
                        } }>
                            <li className="mt-3">
                                <span className="fa-li text-primary"><i className="fas fa-envelope"/></span>
                                <a href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>
                            </li>
                            <li className="mt-3">
                                <span className="fa-li text-primary"><i className="fas fa-phone"/></span>
                                <a href="dial:+31612493287">+31 (0)6 124 932 87</a>
                            </li>
                            <li className="mt-3">
                                <span className="fa-li text-primary"><i className="fas fa-store"/></span>
                                KVK: 89047311 (Cyber Atelier)
                                <br/>
                                <span className="text-muted">(Netherlands Chamber of Commerce No.)</span>
                            </li>
                            <li className="mt-3">
                                <span className="fa-li text-primary"><i className="fas fa-building"/></span>
                                BTW: NL864861539B01
                                <br/>
                                <span className="text-muted">(Netherlands Tax Authorities No.)</span>
                            </li>
                            <li className="mt-3">
                                <span className="fa-li text-primary"><i className="fas fa-map-marker-alt"/></span>
                                Kermispad 119
                                <br/>
                                1033ZB Amsterdam
                                <br/>
                                The Netherlands
                                <br/>
                                <span className="text-muted">(Not a visiting address)</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default React.memo(Contact);
