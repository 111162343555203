import React from "react";
import Helmet from "../components/Helmet";

function PrivacyPolicy() {
    return (
        <React.Fragment>
            <Helmet
                title="Privacy Policy"
            />
            <div className="container py-5">
                <h1 className="text-center my-5 text-blue" style={{ textTransform: "uppercase" }}>
                    Privacy Policy
                </h1>

                <div className="card">
                    <div className="card-body">
                        <p>This privacy statement applies since May 3rd 2021 until further notice.</p>

                        <p>
                            Cyber Atelier respects the privacy of the clients, in particular their rights regarding the
                            automatic processing of the personal data. We have formulated and implemented complete transparency
                            with our customers with regard to the processing of the personal data, it’s purpose and the
                            possibilities to exercise your legal rights in the best way.
                        </p>
                        <p>
                            If you require any additional information about the protection of the personal data, please visit
                            the website of the Dutch Data Protection Authority (Autoriteit Persoonsgegevens)
                        </p>

                        <h2>Article 1 - Legal definition</h2>
                        <p>
                            Party responsible for processing personal data: Cyber Atelier established on Kermispad 119,
                            1033ZB Amsterdam, The Netherlands, Chamber of Commerce number: 89047311
                        </p>

                        <h2>Article 2 - The processing of personal data</h2>
                        <ol>
                            <li>
                                Personal data means any information relating to an identified or identifiable natural person
                            </li>
                            <li>
                                An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number , location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, menta, economic, cultural or social identity of that natural person
                            </li>
                            <li>
                                We process the following categories of the personal data from you:
                                <ul>
                                    <li>Full name</li>
                                    <li>Address</li>
                                    <li>Phone number</li>
                                    <li>E-mail address</li>
                                    <li>IP address</li>
                                    <li>Any other information you disclose on our website, in correspondence and by telephone</li>
                                    <li>Information about your activities on our website</li>
                                </ul>
                            </li>
                        </ol>

                        <h2>Article 3 - Purpose of the processing</h2>
                        <p>
                            We do not collect your personal information without a purpose. Your personal data are processed for:
                        </p>
                        <ul>
                            <li>To maintain relationships with you</li>
                            <li>To process your orders</li>
                            <li>For announcements via mail, e-mails and/or newsletters</li>
                        </ul>

                        <h2>Article 4 - Registration of personal data</h2>
                        <p>
                            Your personal data are recorded in an (electronic) register.
                        </p>

                        <h2>Article 5 - Your rights regarding information</h2>
                        <ol>
                            <li>
                                Pursuant to Article 13 paragraph 2 sub b GDPR each data subject has the right to information on
                                and access to, rectification, erasure and restriction of processing of his personal data, as
                                well as the right to object to the processing and the right to data portability.
                            </li>
                            <li>
                                You can exercise these rights by contacting us at cyberatelier.art@gmail.com
                            </li>
                            <li>
                                Each request must be accompanied by a copy of a valid ID, on which you put your signature and
                                state the address where we can contact you.
                            </li>
                            <li>
                                Within one month of the submitted request, you will receive an answer from us.
                            </li>
                            <li>
                                Depending on the complexity and the number of the requests this period may be extended to two
                                months.
                            </li>
                        </ol>

                        <h2>Article 6 - Legal obligations</h2>
                        <p>
                            In case of infringement of any or regulation, of which a visitor is suspected and for which the
                            authorities require the personal data collected by the collector, they will be provided to them
                            after an explicit and reasoned request of those authorities, after which these personal data do not
                            fall anymore under the protection of the provisions of this Privacy policy.
                        </p>

                        <h2>Article 7 - Commercial offers</h2>
                        <ol>
                            <li>
                                You may receive commercial offers from the collector. If you do not wish to receive them
                                (anymore), please send us an email to the following
                                address: <a href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>.
                            </li>
                            <li>
                                Your personal data will not be used by our partners for commercial purposes.
                            </li>
                        </ol>

                        <h2>Article 8 - Data retention</h2>
                        <p>
                            The collected data are used and retained for the duration determined by law.
                        </p>

                        <h2>Article 9 - Application Law</h2>
                        <p>
                            These conditions are governed by Dutch law. The court in the district where the collector has its
                            place of business has the sole jurisdiction if any dispute regarding these conditions may arise,
                            save when a legal exception applies.
                        </p>

                        <h2>Article 10 - Contact</h2>
                        <p>
                            For requests, questions, product information or more information, please contact: <a href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>.
                        </p>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default React.memo(PrivacyPolicy);
