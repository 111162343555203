import React, {
    createContext
} from "react";
import {
    matchPath,
    withRouter
} from "react-router-dom";
import {
    isWebpSupported
} from "react-image-webp/dist/utils";

const defaultTheme = "home";
const ThemeContext = createContext(null);

class ThemeManagerInternal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.themes = [
            "home",
            "product-page",
            "about-us",
            "category"
        ];
        this.state = {
            themeContext: {
                theme: defaultTheme,
                setTheme: this.setTheme.bind(this)
            }
        }
        this.updateBodyClass(defaultTheme);
    }

    componentDidMount() {
        this.setThemeByPath();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location !== this.props.location) {
            this.setThemeByPath();
        }
    }

    setContextState(state) {
        this.setState(previousState => {
            return { themeContext: { ...previousState.themeContext, ...state } }
        });
    }

    setThemeByPath() {
        const pathname = this.props.location.pathname;
        if(matchPath(pathname, { path: "/product" })) {
            this.setTheme("product-page");
        } else if(matchPath(pathname, { path: "/about-us" })) {
            this.setTheme("about-us");
        } else if(matchPath(pathname, { path: "/category" })) {
            this.setTheme("category");
        } else {
            this.setTheme("home");
        }
    }

    setTheme(theme) {
        if(theme === this.state.themeContext.theme) {
            return;
        }
        if(!this.themes.includes(theme)) {
            throw new Error("Invalid theme provided: " + theme);
        }
        this.setContextState({ theme });
        this.updateBodyClass(theme);
    }

    updateBodyClass(theme) {
        if(!this.bodyRef) {
            this.bodyRef = document.getElementsByTagName("BODY")[0];
        }

        if(isWebpSupported() && !this.bodyRef.classList.contains("webp")) {
            this.bodyRef.classList.add("webp");
        }

        this.themes.forEach((themeToRemove) => {
            if(theme !== themeToRemove && this.bodyRef.classList.contains(themeToRemove)) {
                this.bodyRef.classList.remove(themeToRemove);
            }
        });
        if(!this.bodyRef.classList.contains(theme)) {
            this.bodyRef.classList.add(theme);
        }
    }

    render() {
        return (
            <ThemeContext.Provider value={ this.state.themeContext }>
                { this.props.children }
            </ThemeContext.Provider>
        )
    }
}
export const ThemeManager = withRouter(ThemeManagerInternal);

export default ThemeContext;
