import React from "react";
import {
    ProductCollectionBarInternal,
    withShopContext
} from "@shopcrate/shopcrate-framework";

import CAProductCard from "./CAProductCard";

class CollectionBar extends ProductCollectionBarInternal {
    getProductCard(product) {
        return (
            <div className="col d-flex justify-content-center">
                <div style={{ maxWidth: "300px" }}>
                    <div className="card">
                        <div className="card-body">
                            <CAProductCard size="large" product={ product }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withShopContext(React.memo(CollectionBar));
