import React from "react";

import Helmet from "../components/Helmet";
import ProductPage from "../components/ProductPage";
import Page404 from "../pages/Page404";

function ProductLayout() {
    return (
        <ProductPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    )
}

export default React.memo(ProductLayout);
