import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    useShopCrateRoutes
} from "@shopcrate/shopcrate-framework";

import useTracking from "./hooks/useTracking";
import Navbar from "./components/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";
import Layout from "./layout/Layout";
import ProductLayout from "./layout/ProductLayout";
import CategoryLayout from "./layout/CategoryLayout";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Page404 from "./pages/Page404";

const layouts = {
    regular: Layout,
    checkout: Layout,
    account: Layout,
    product: ProductLayout,
    category: CategoryLayout
}

function ShopRouter() {
    useTracking(["default"]);
    const shopCrateRoutes = useShopCrateRoutes(layouts);
    return (
        <React.Fragment>
            <ScrollToTop/>
            <div className="site-content d-flex flex-column">
                <Navbar/>

                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/about-us" component={AboutUs}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/privacypolicy" component={PrivacyPolicy}/>
                    <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>

                    { shopCrateRoutes }

                    <Route exact path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/" component={Page404}/>
                </Switch>
            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
