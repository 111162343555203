import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import ReactGA from "react-ga4";
import { clarity } from "clarity-js";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if (development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://74fbfc29135a45beb62bc85893110889@sentry1.zsnode.com/28",
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
    });
}

// Start Microsoft Clarity
clarity.start({
    projectId: "nrdzzx1shf",
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true
});

// Start Google Analytics
ReactGA.initialize([
    { trackingId: "UA-196786566-1", gaOptions: { name: "default" } },
]);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
