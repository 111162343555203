import React from "react";

function HomeSocialLinks() {
    return (
        <ul
            className="nav text-center justify-content-center"
            style={{ fontSize: "2rem" }}
        >
            <li className="nav-item">
                <a className="nav-link active" href="https://tiktok.com/@cyberatelier" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-tiktok"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active" href="https://instagram.com/cyberatelier" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active" href="https://youtube.com/channel/UCuwjHMOI0X8zfRrQLx22d4g" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"/>
                </a>
            </li>
        </ul>
    );
}

export default React.memo(HomeSocialLinks);
