import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    ProductCollectionBase,
    Loading
} from "@shopcrate/shopcrate-framework";

class CollectionFooterList extends ProductCollectionBase {
    getProductCard(product) {
        return (
            <li className="nav-item">
                <Link
                    to={ "/product/" + product.id + "/" + product.url }
                    className="nav-link active footer-link"
                >
                    { product.name }
                </Link>
            </li>
        )
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.collection === null) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                { !this.props.noTitle && (
                    <h3 className="footer-title">
                        { this.state.collection.name }
                    </h3>
                )}
                <ul className="nav flex-column">
                    { this.state.collection.products.map((product, index) => (
                        <React.Fragment key={ index }>
                            { this.getProductCard(product) }
                        </React.Fragment>
                    ))}
                </ul>
            </React.Fragment>
        )
    }
}

export default CollectionFooterList;
