import React from "react";
import {
    Trans
} from "react-i18next";
import {
    ProductPageContext,
    ProductPageManager,
    ProductImageCarousel,
    ProductPageTitle,
    ProductPageDescription,
    ProductPagePreorderNotice,
    ProductPageAddToCardButton,
    ProductPagePrice,
    ProductPageStock,
    ProductLimitPerOrderNotice,
    ProductEndDateCountdown,
    ProductEndDate
} from "@shopcrate/shopcrate-framework";

import useWindowSize from "../hooks/WindowSize";

function ProductPage(props) {
    const windowSize = useWindowSize();
    return (
        <ProductPageManager { ...props }>
            { props.helmet && (
                <ProductPageContext.Consumer>
                    { productContext => props.helmet(productContext) }
                </ProductPageContext.Consumer>
            )}
            <div className="container mt-5 mb-5">
                <div className="mb-5 text-blue" style={{ textTransform: "uppercase" }}>
                    <ProductPageTitle showSubtitle={ !!props.showSubtitle ? props.showSubtitle : true }/>
                </div>
                <div className="row">
                    <div className="col-md-8 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <ProductImageCarousel height={ Math.min(500, windowSize.height / 2) }/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <span className="h1">
                                    <ProductPagePrice/>
                                </span>
                                <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                                    <b><ProductPageStock/></b>
                                </p>
                                <p style={{ fontSize: "0.8rem" }}>
                                    <b><ProductLimitPerOrderNotice/></b>
                                </p>
                                <h5 className="mt-3">
                                    <ProductPagePreorderNotice/>
                                </h5>
                                <div className="mt-3">
                                    <ProductPageAddToCardButton/>
                                </div>
                                <p className="mt-3">
                                    <ProductEndDate/>
                                </p>
                                <div className="text-center mt-3" style={{ fontSize: "1.75rem" }}>
                                    <ProductEndDateCountdown/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <h2>
                                    <Trans i18nKey="description"/>
                                </h2>
                                <div className="">
                                    <ProductPageDescription/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProductPageManager>
    )
}

export default React.memo(ProductPage);
