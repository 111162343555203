import React from "react";

function PrivacyNoticeCard() {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <p className="card-text">
                    By clicking on "Payment", you accept the <a href="/privacypolicy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="/terms-and-conditions" target="_blank" rel="noreferrer noopener">Terms and Conditions</a>.
                </p>
            </div>
        </div>
    )
}

export default React.memo(PrivacyNoticeCard);
