import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";
import CollectionFooterList from "./CollectionFooterList";

function FooterSocialIcons() {
    return (
        <ul className="nav justify-content-center">
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://tiktok.com/@cyberatelier" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-tiktok"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://instagram.com/cyberatelier" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://youtube.com/channel/UCuwjHMOI0X8zfRrQLx22d4g" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"/>
                </a>
            </li>
        </ul>
    );
}

function Footer() {
    const firstYear = 2021;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <Jumbotron fluid className="footer mb-0">
                <Container>
                    <Row>
                        <Col md="4" className="my-3">
                            <h3 className="footer-title">Magazine</h3>
                            <CollectionFooterList collection="magazines-footer-list" noTitle/>
                        </Col>
                        <Col md="4" className="my-3">
                            <h3 className="footer-title">Webshop</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/privacypolicy" className="nav-link active footer-link">Privacy Policy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/terms-and-conditions" className="nav-link active footer-link">Terms and Conditions</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="my-3">
                            <h3 className="footer-title">Contact</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: 0
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li footer-link"><i className="fas fa-envelope"/></span>
                                    <a className="footer-link" href="mailto:cyberatelier.art@gmail.com">cyberatelier.art@gmail.com</a>
                                </li>
                            </ul>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: 0
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li"/>
                                    <Link to="/contact" className="footer-link">Show more</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="footer-secondary mb-0 py-4">
                <Container className="text-white">
                    <div className="d-flex flex-column flex-lg-row align-items-md-center">
                        <div className="text-center d-lg-none flex-grow-1 mb-3">
                            <FooterSocialIcons/>
                        </div>
                        <div className="flex-lg-grow-1 text-center text-lg-left">
                            &copy; {yearString} Cyb#r Atelier.
                            <br/>
                            <small>
                                A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>SR Productions</a>.
                                <span className="d-md-none"><br/></span>{" "}
                                Powered by <a href="https://shopcrate.nl" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>ShopCrate</a>.
                            </small>
                        </div>
                        <div className="d-none d-lg-block">
                            <FooterSocialIcons/>
                        </div>
                    </div>
                </Container>
            </Jumbotron>
        </React.Fragment>
    )
}

export default React.memo(Footer);
